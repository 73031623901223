import React from "react";
import Navbar from "./Navbar";
import '../styles.css'



function Header(){
    return (
       <Navbar/>
    )
}

export default Header;